import React, { useContext } from 'react';
import styled from 'styled-components/macro';
import { Link as RouterLink } from 'react-router-dom';

import { Icon } from '../Icon';
import { useTranslation } from 'react-i18next';
import { Col, Row } from '../Grid';
import { AuthContext } from '../../../context/AuthContext';
import { Search } from '../Search';
import { NavButton, NavLink } from './NavLink';
import { PrivateBlock } from '../PrivateBlock';
import { palette } from '../../../styles/tools';
import { LogoImg } from '../LogoImg';

export function NavBar() {
  const { t } = useTranslation();
  const { signOut } = useContext(AuthContext);

  return (
    <Wrapper>
      <Row justifyContent="space-between" alignItems="center" flexWrap="nowrap">
        <Col>
          <Row flexWrap="nowrap">
            <Col>
              <LogoLink to="/">
                <LogoImg />
              </LogoLink>
            </Col>
            <Col gutter={1.5}>
              <Nav>
                <NavLink to="/profile" tags={['coach']}>
                  <Icon name="person" />
                  <br />
                  {t('navigation.profile')}
                </NavLink>
                <NavLink to="/access-management" tags={['coach']}>
                  <Icon name="key" />
                  <br />
                  {t('navigation.accessManagement')}
                </NavLink>
                <NavLink to="/coaches" permissions={['access_coaches']}>
                  <Icon name="person" />
                  <br />
                  {t('navigation.coaches')}
                </NavLink>
                <NavLink to="/collections" permissions={['access_collections']}>
                  <Icon name="discover" />
                  <br />
                  {t('navigation.discover')}
                </NavLink>
                <NavLink to="/shortlists" permissions={['access_shortlists']}>
                  <Icon name="bookmark" />
                  <br />
                  {t('navigation.shortlists')}
                </NavLink>
                <NavLink to="/news" permissions={['access_news']}>
                  <Icon name="news" />
                  <br />
                  {t('navigation.spotlight')}
                </NavLink>
                <NavLink to="/compare" permissions={['access_coaches']}>
                  <Icon name="compare" />
                  <br />
                  {t('navigation.compare')}
                </NavLink>
                <NavLink to="/player-relation" permissions={['access_coaches']}>
                  <Icon name="relation-1" />
                  <br />
                  {t('navigation.relation')}
                </NavLink>
              </Nav>
            </Col>
          </Row>
        </Col>
        <Col xs="auto">
          <Row alignItems="center" justifyContent="flex-end" flexWrap="nowrap">
            <PrivateBlock tags={['!coach']}>
              <Col xs="auto" gutter={2} style={{ maxWidth: 325 }}>
                <Search />
              </Col>
            </PrivateBlock>
            <Col>
              <Nav>
                <NavLink to="/faq">
                  <Icon name="question" />
                  <br />
                  {t('navigation.faq')}
                </NavLink>
                <NavLink to="/settings">
                  <Icon name="settings" />
                  <br />
                  {t('navigation.settings')}
                </NavLink>
                <NavLink to="/admin" tags={['admin']}>
                  <Icon name="key" />
                  <br />
                  {t('navigation.admin')}
                </NavLink>
                <NavButton onClick={signOut}>
                  <Icon name="log-out" />
                  <br />
                  {t('navigation.logOut')}
                </NavButton>
              </Nav>
            </Col>
          </Row>
        </Col>
      </Row>
    </Wrapper>
  );
}

const Wrapper = styled.header`
  padding: 14px 16px;
  background: ${palette('card', 'default')};

  @media print {
    display: none;
  }
`;

const Nav = styled.div`
  display: flex;
  margin: 0 -8px;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

const LogoLink = styled(RouterLink)`
  display: block;
  padding: 8px;
`;
