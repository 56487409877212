import React, { PropsWithChildren } from 'react';
import { ThemeProvider as OriginalThemeProvider } from 'styled-components';
import { useSelector } from 'react-redux';

import { useThemeSlice } from './slice';
import { selectTheme } from './slice/selectors';

export const ThemeProvider = (props: PropsWithChildren) => {
  const { children } = props;

  useThemeSlice();

  const theme = useSelector(selectTheme);

  return (
    <OriginalThemeProvider theme={theme}>{children}</OriginalThemeProvider>
  );
};
