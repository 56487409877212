import { call, put, takeLatest } from 'redux-saga/effects';
import { AxiosError, AxiosResponse } from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';

import { customCoachActions as actions } from '.';
import { CreateAction, ErrorType, RequestBody } from './types';
import { Media } from '../../../../types/Media';
import { api } from '../../../../services/api';
import { Coach } from '../../../../types/Coach';

function* addOrUpdate(action: PayloadAction<CreateAction>) {
  const { values, callback } = action.payload;

  try {
    const {
      id,
      firstname,
      surname,
      current_team,
      coach_type,
      country,
      birthday,
      contract,
      media,
      current_license,
      languages,
    } = values;

    const body: RequestBody = {
      firstname,
      surname,
      current_team_id: current_team ? (current_team.value as number) : null,
      coach_type_id: coach_type ? (coach_type.value as number) : null,
      country_id: country ? (country.value as number) : null,
      birthday,
      contract,
      media_id: (media as Media)?.id || null,
      current_license_id: current_license
        ? (current_license.value as number)
        : null,
      languages: languages
        ? languages.map(({ value }) => value as number)
        : null,
    };

    if ((media as { file: File })?.file) {
      const file = (media as { file: File })?.file;
      const formData = new FormData();
      formData.append('file', file, file.name);

      const mediaResponse: AxiosResponse<Media> = yield call(
        api.post,
        'file',
        formData,
      );

      body.media_id = mediaResponse?.data.id;
    }

    const response: AxiosResponse<Coach> = yield call(
      api.post,
      `coaches/custom${id ? `/${id}` : ''}`,
      body,
    );

    yield put(actions.addOrUpdateSuccess());
    callback(response.data);
  } catch (error) {
    const err = error as AxiosError;

    if (err.response?.status === 400) {
      yield put(actions.addOrUpdateError(ErrorType.COACH_EXISTS));
    } else {
      yield put(actions.addOrUpdateError(ErrorType.SERVER_ERROR));
    }
  }
}

export function* customCoachSaga() {
  yield takeLatest(actions.addOrUpdate.type, addOrUpdate);
}
