import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';

import { customCoachSaga } from './saga';
import { CreateAction, CustomCoachState, ErrorType, FormFields } from './types';

const initialValues: FormFields = {
  firstname: '',
  surname: '',
  current_team: null,
  coach_type: null,
  country: null,
  birthday: '',
  contract: '',
  media: null,
  current_license: null,
  languages: null,
};

export const initialState: CustomCoachState = {
  initialValues,
  isOpen: false,
  loading: false,
  error: null,
};

const slice = createSlice({
  name: 'customCoach',
  initialState,
  reducers: {
    openForm(state, action: PayloadAction<FormFields | undefined>) {
      if (action.payload) {
        state.initialValues = action.payload;
      }

      state.isOpen = true;
      state.error = null;
    },
    closeForm(state) {
      state.isOpen = false;
      state.error = null;
      state.initialValues = initialValues;
    },

    addOrUpdate(state, action: PayloadAction<CreateAction>) {
      state.loading = true;
      state.error = null;
    },
    addOrUpdateSuccess(state) {
      state.loading = false;
      state.isOpen = false;
    },
    addOrUpdateError(state, action: PayloadAction<ErrorType>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { actions: customCoachActions } = slice;

export const useCustomCoachSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: customCoachSaga });
  return { actions: slice.actions };
};
