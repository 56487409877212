/**
 * Asynchronously loads the component for HomePage
 */

import { lazyLoad } from 'utils/loadable';

export const CoachesPage = lazyLoad(
  () => import('./index'),
  module => module.CoachesPage,
);
