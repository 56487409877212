import React from 'react';
import { components, ContainerProps } from 'react-select';

export const ContainerComponent = ({ children, ...props }: ContainerProps) => {
  return (
    <components.SelectContainer
      {...props}
      className={`${props.className} ${
        props?.selectProps?.menuIsOpen ? 'is-focused' : ''
      }`}
    >
      {children}
    </components.SelectContainer>
  );
};
