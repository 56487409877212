import React from 'react';
import { components, ControlProps } from 'react-select';

import { Icon } from '../../Icon';

export const ControlComponent = ({ children, ...props }: ControlProps) => {
  return (
    <components.Control {...props}>
      <Icon name="search" />
      {children}
    </components.Control>
  );
};
