import { PropsWithTheme } from './types';

type Props = PropsWithTheme & {
  palette?: string;
  tone?: number;
};

const toArray = (arg: any) => (Array.isArray(arg) ? arg : [arg]);

const clamp = (number: number, min: number, max: number) => {
  if (number < min) return min;
  if (number > max) return max;
  return number;
};

export const palette =
  (
    element: string,
    keyOrTone?: string | number,
    toneOrDefaultValue?: any,
    defaultValue?: any,
  ) =>
  (props: Props) => {
    const key = (props.palette || keyOrTone) as string;

    const tone =
      typeof keyOrTone === 'number'
        ? keyOrTone
        : typeof toneOrDefaultValue === 'number'
        ? toneOrDefaultValue
        : props.tone || 0;
    const finalDefaultValue =
      toneOrDefaultValue !== tone ? toneOrDefaultValue : defaultValue;

    if (!props.theme.palette[element] || !props.theme.palette[element][key]) {
      return finalDefaultValue;
    }

    const tones = toArray(props.theme.palette[element][key]);

    if (tone < 0) {
      return tones[clamp(tones.length + tone, 0, tones.length - 1)];
    }

    return tones[clamp(tone, 0, tones.length - 1)];
  };

export default palette;
