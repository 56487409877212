import i18n from 'i18next';

export function getTranslated(
  item: any,
  fallbackKey?: string,
  key: string = 'name',
): string {
  if (!item) return '';

  return item[`${key}_${i18n.language}`] || item[fallbackKey || `${key}_en`];
}
