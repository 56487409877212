import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { profileSaga } from './saga';
import { ProfileState } from './types';
import { User, UserDto } from '../../../../../types/User';

export const initialState: ProfileState = {
  user: null,
  getLoading: false,
  updateLoading: false,
  error: null,
};

const slice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    getUser(state) {
      state.user = initialState.user;
      state.getLoading = true;
      state.updateLoading = false;
    },
    updateUser(state, action: PayloadAction<Partial<UserDto>>) {
      state.getLoading = false;
      state.updateLoading = true;
    },
    changeUserData(state, action: PayloadAction<User>) {
      state.user = action.payload as User;
      state.getLoading = false;
      state.updateLoading = false;
    },
  },
});

export const { actions: profileActions } = slice;

export const useProfileSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: profileSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useProfileSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
