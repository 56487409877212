import { createSelector } from '@reduxjs/toolkit';
import i18n from 'i18next';
import _orderBy from 'lodash/orderBy';

import { RootState } from 'types';
import { initialState } from '.';
import { NewsTypeIds } from '../../types/Dict';
import { Option } from '../components/Select/types';
import { selectUserClientCountryId } from '../pages/SettingsPage/Profile/slice/selectors';
import { getTranslated } from '../../utils/get-translated';

const countriesSort =
  (countryid = 47) =>
  (a, b) => {
    if (a.value === countryid) return -1;
    if (b.value === countryid) return 0;
    if (a.label === i18n.t('label.competitions')) return 1;
    if (b.label === i18n.t('label.competitions')) return -1;
    return a.label > b.label ? 1 : b.label > a.label ? -1 : 0;
  };

const selectLanguage = () => i18n.language;

const selectSlice = (state: RootState) => state?.dict || initialState;

const selectNewsTypeId = (state: RootState, props: { typeId: NewsTypeIds }) =>
  props.typeId;

const selectShowInactive = (
  state: RootState,
  props?: { showInactive?: boolean },
) => props?.showInactive || false;

export const selectLoaded = createSelector(
  [selectSlice],
  state => state.loaded,
);

export const selectDictLoading = createSelector(
  [selectSlice],
  state => state.loading,
);

export const selectLanguages = createSelector(
  [selectSlice],
  state => state.languages,
);

export const selectActiveLanguages = createSelector(
  [selectLanguages, selectLanguage],
  (languages, lng) => languages.filter(({ isactive }) => isactive),
);

export const selectLanguagesForFields = createSelector(
  [selectActiveLanguages, selectLanguage],
  (languages, lng) => {
    return [
      ...languages.filter(({ code }) => code === 'en'),
      ...languages
        .filter(({ code }) => code !== 'en')
        .sort((a, b) => a.id - b.id),
    ].map(language => ({
      code: language.code as 'en' | 'de' | 'fr' | 'es' | 'it' | 'tr',
      label: language[`name_${lng}`] || language.name_en,
    }));
  },
);

export const selectLanguageOptions = createSelector(
  [selectLanguages, selectLanguage, selectShowInactive],
  (languages, lng, showInactive) => {
    const activeLanguages = languages
      .filter(({ isactive }) => showInactive || isactive)
      .map(language => ({
        value: language.id,
        label: language[`name_${lng}`] || language.name_en,
      }));

    return _orderBy(activeLanguages, 'label');
  },
);

export const selectLicenses = createSelector(
  [selectSlice],
  state => state.licenses,
);

export const selectLicenseOptions = createSelector(
  [selectLicenses, selectLanguage],
  (licenses, language) =>
    licenses.map(licence => ({
      value: licence.id,
      label: licence[`license_name_${language}`] || licence.license_name_en,
    })),
);

export const selectCoachIndexes = createSelector(
  [selectSlice],
  state => state.coachIndexes,
);

export const selectCoachTypes = createSelector(
  [selectSlice],
  state => state.coachTypes,
);

export const selectCoachTypeOptions = createSelector(
  [selectCoachTypes, selectLanguage],
  (types, language) =>
    types.map(type => ({
      value: type.id,
      label: type[`name_${language}`] || type.name_en,
    })),
);

export const selectGameStyles = createSelector(
  [selectSlice],
  state => state.gameStyles,
);

export const selectGameStyleOptions = createSelector(
  [selectGameStyles, selectLanguage],
  (gameStyles, language) =>
    gameStyles
      .map(style => ({
        value: style.id,
        label: style[`shortname_${language}`] || style.shortname_en,
      }))
      .filter(({ value }) => value !== 1),
);

export const selectFormations = createSelector(
  [selectSlice],
  state => state.formations,
);

export const selectFormationOptions = createSelector(
  [selectFormations],
  formations =>
    formations.map(({ id, wy_name }) => ({
      value: id,
      label: wy_name,
    })),
);

export const selectNewsTypes = createSelector(
  [selectSlice, selectLanguage],
  (state, language) =>
    state.newsTypes.map(type => ({
      ...type,
      name: type[`name_${language}`] || type.name_en,
    })),
);

export const selectNewsTypeById = createSelector(
  [selectNewsTypes, selectNewsTypeId],
  (newsTypes, typeId) => {
    return newsTypes.find(({ id }) => id === typeId);
  },
);

export const selectCountries = createSelector(
  [selectSlice, selectLanguage],
  (state, language) =>
    state.countries.map(country => ({
      ...country,
      name: country[`country_${language}`] || country.country_en,
    })),
);

export const selectCountryOptions = createSelector(
  [selectCountries],
  countries =>
    countries.map(({ id, name }) => ({
      value: id,
      label: name,
    })),
);

export const selectTeams = createSelector(
  [selectSlice, selectLanguage],
  (state, language) =>
    state.teams.map(team => ({
      ...team,
      name: getTranslated(team, 'name'),
    })),
);

export const selectTeamOptions = createSelector(
  [selectTeams, selectLanguage],
  teams =>
    teams.map(({ id, name }) => ({
      value: id,
      label: name,
    })),
);

export const selectClubTeamOptions = createSelector(
  [selectTeams, selectLanguage, selectUserClientCountryId],
  (teams, language, countryId) => {
    const clubTeams = teams.filter(({ type }) => type === 'club');

    const countries: any = {};
    const groups: any = {};

    for (let i = 0, l = clubTeams.length; i < l; i++) {
      const competition = clubTeams[i];
      const { id, name, country_id, country } = competition;
      const countryIdString = `${country_id}`;

      if (!countries[countryIdString]) countries[countryIdString] = country;
      if (!groups[countryIdString]) groups[countryIdString] = [];
      groups[countryIdString].push({
        value: id,
        label: name,
        type: 'option',
      });
    }

    // @ts-ignore
    const options: {
      value: number;
      label: string;
      type: string;
      children: Option[];
    }[] = Object.entries(groups).map(([countryId, children]) => ({
      label: countries[countryId]
        ? getTranslated(countries[countryId], 'country_en', 'country')
        : i18n.t('label.competitions'),
      value: +countryId ? +countryId : -1,
      type: 'group',
      children,
    }));

    return options.sort(countriesSort(countryId || undefined));
  },
);

export const selectNationalTeamOptions = createSelector(
  [selectTeams, selectLanguage, selectUserClientCountryId],
  (teams, language, countryId) => {
    const clubTeams = teams.filter(({ type }) => type === 'national');

    const countries: any = {};
    const groups: any = {};

    for (let i = 0, l = clubTeams.length; i < l; i++) {
      const competition = clubTeams[i];
      const { id, name, country_id, country } = competition;
      const countryIdString = `${country_id}`;

      if (!countries[countryIdString]) countries[countryIdString] = country;
      if (!groups[countryIdString]) groups[countryIdString] = [];
      groups[countryIdString].push({
        value: id,
        label: name,
        type: 'option',
      });
    }

    // @ts-ignore
    const options: {
      value: number;
      label: string;
      type: string;
      children: Option[];
    }[] = Object.entries(groups).map(([countryId, children]) => ({
      label: countries[countryId]
        ? getTranslated(countries[countryId], 'country_en', 'country')
        : i18n.t('label.competitions'),
      value: +countryId ? +countryId : -1,
      type: 'group',
      children,
    }));

    return options.sort(countriesSort(countryId || undefined));
  },
);

export const selectCompetitions = createSelector(
  [selectSlice],
  state => state.competitions,
);

export const selectCompetitionOptions = createSelector(
  [selectCompetitions],
  competitions =>
    competitions.map(({ id, name }) => ({
      value: id,
      label: name,
    })),
);

export const selectCompetitionOptionsGroupedByCountry = createSelector(
  [selectCompetitions, selectLanguage, selectUserClientCountryId],
  (competitions, language, countryId) => {
    const countries: any = {};
    const groups: {
      [key: string]: {
        value: number;
        label: string;
        type: string;
      }[];
    } = {};

    for (let i = 0, l = competitions.length; i < l; i++) {
      const competition = competitions[i];
      const { id, name, country_id, country } = competition;
      const countryIdString = `${country_id}`;

      if (!countries[countryIdString]) countries[countryIdString] = country;
      if (!groups[countryIdString]) groups[countryIdString] = [];
      groups[countryIdString].push({
        value: id,
        label: name,
        type: 'option',
      });
    }

    const options: {
      value: number;
      label: string;
      type: string;
      children: Option[];
    }[] = Object.entries(groups).map(
      ([countryId, children]: [string, Option[]]) => ({
        label: countries[countryId]
          ? getTranslated(countries[countryId], 'country_en', 'country')
          : i18n.t('label.competitions'),
        value: +countryId ? +countryId : -1,
        type: 'group',
        children,
      }),
    );

    return options.sort(countriesSort(countryId || undefined));
  },
);

export const selectRadiusOptions = createSelector([], () =>
  [20, 50, 100, 150].map(d => ({
    value: d,
    label: `${d} km`,
  })),
);

export const selectAges = createSelector([selectSlice], state => state.ages);

export const selectAgeOptions = createSelector([selectAges], ages =>
  ages
    .filter(({ name }) => name)
    .sort((a, b) => {
      return (
        parseInt(b.name.replace(/\D/g, '')) -
        parseInt(a.name.replace(/\D/g, ''))
      );
    })
    .map(({ id, name }) => ({
      value: id,
      label: name,
    })),
);
