import { all, call, put, takeLatest } from 'redux-saga/effects';
import { dictActions as actions } from '.';
import { api } from '../../services/api';
import { AxiosError, AxiosResponse } from 'axios';
import { Team } from '../../types/Team';

function* dataRequest(route) {
  try {
    return yield call(api.get, route);
  } catch (err) {
    return err as AxiosError;
  }
}

function* getDict() {
  const result = yield all({
    languages: dataRequest('/languages'),
    agencies: dataRequest('/dict/agencies'),
    coachIndexes: dataRequest('/dict/coach-indexes'),
    coachTypes: dataRequest('/dict/coach-types'),
    licenses: dataRequest('/dict/licenses'),
    newsTypes: dataRequest('/dict/news-types'),
    seasons: dataRequest('/dict/seasons'),
    gameStyles: dataRequest('/dict/game-style'),
    formations: dataRequest('/dict/formations/used'),
    countries: dataRequest('/countries/all'),
    teams: dataRequest('/teams/all'),
    competitions: dataRequest('/competitions/all'),
    ages: dataRequest('/age'),
  });

  yield put(actions.fillDict(result));
}

export function* dictSaga() {
  yield takeLatest(actions.getDict.type, getDict);
}
