import React, { useContext } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { AuthContext } from '../../context/AuthContext';
import {
  UserValidateProps,
  validateRoutePermissions,
} from '../../utils/validate-user-permissions';
import { PageWrapper } from '../components/PageWrapper';

type Props = RouteProps & UserValidateProps;

export const PrivateRoute = ({ permissions, tags, ...rest }: Props) => {
  const { isAuthenticated, user, loadingUserData } = useContext(AuthContext);
  const { hasAllPermissions, hasAllTags } = validateRoutePermissions({
    user,
    permissions,
    tags,
  });
  const hasAccess = hasAllPermissions && hasAllTags;

  if (loadingUserData) {
    return <PageWrapper loading />;
  }

  if (!isAuthenticated) {
    return (
      <Redirect
        to={{ pathname: '/auth/login', state: { from: rest.location } }}
      />
    );
  }

  if (!hasAccess) {
    return <Redirect to={{ pathname: '/', state: { from: rest.location } }} />;
  }

  return <Route {...rest} />;
};
