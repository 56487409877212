import { useEffect } from 'react';

declare global {
  interface Window {
    gtag: any;
    dataLayer: any;
  }
}
export const GoogleTag = ({ tagId }) => {
  //const script = `<!-- Google tag (gtag.js) --><script async src=""></script><script></script>`;
  const script = document.createElement('script');
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtag/js?id=${tagId}`;

  function onScriptLoad() {
    window.dataLayer = window.dataLayer || [];

    window.gtag = (...args: string[]) => {
      window.dataLayer.push(args);
    };

    window.gtag('js', new Date());
    window.gtag('config', tagId);

    console.info('GTM Loaded and ready to use');
  }

  useEffect(() => {
    document.body.appendChild(script);
    script.addEventListener('load', onScriptLoad);

    return () => {
      document.body.removeChild(script);
    };
  }, [tagId]);

  return null;
};
