import { PropsWithChildren, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectThemeKey } from '../../styles/theme/slice/selectors';
import { ThemeKeyType } from '../../styles/theme/slice/types';
import { useThemeSlice } from '../../styles/theme/slice';

export const PrintProvider = (props: PropsWithChildren) => {
  const { children } = props;
  const themeKey = useSelector(selectThemeKey);
  const [prevTheme, setPrevTheme] = useState<ThemeKeyType>(themeKey);
  const dispatch = useDispatch();
  const { actions } = useThemeSlice();

  const mediaQuery = useMemo(() => {
    return window?.matchMedia('print') || undefined;
  }, []);

  const beforePrint = () => {
    setPrevTheme(themeKey);
    dispatch(actions.changeTheme('light'));
  };

  const afterPrint = () => {
    dispatch(actions.changeTheme(prevTheme));
  };

  const mediaQueryListener = event => {
    if (event.matches) {
      beforePrint();
    } else {
      afterPrint();
    }
  };

  useEffect(() => {
    if (mediaQuery) {
      mediaQuery.addListener(mediaQueryListener);

      return () => {
        mediaQuery.removeListener(mediaQueryListener);
      };
    } else {
      window.addEventListener('beforeprint', beforePrint);
      window.addEventListener('afterprint', afterPrint);

      return () => {
        window.removeEventListener('beforeprint', beforePrint);
        window.removeEventListener('afterprint', afterPrint);
      };
    }
  }, []);

  return <>{children}</>;
};
