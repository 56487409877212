import React from 'react';
import styled from 'styled-components';
import { ifProp } from 'styled-tools';

import { ReactComponent as CheckboxIcon } from './assets/checkbox.svg';
import { palette, theme } from '../../../styles/tools';

export type CheckboxOnChangeEvent = (
  name: string,
  value: any,
  checked: boolean,
) => void;

interface StyledCheckboxProps {
  checked?: boolean;
}

interface CheckboxProps extends StyledCheckboxProps {
  label?: string;
  onChange: CheckboxOnChangeEvent;
  name: string;
  value?: any;
}

export const CheckboxContainer = styled.label`
  display: block;
  position: relative;
  padding-left: 26px;
  min-height: 20px;
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const StyledCheckbox = styled.div<StyledCheckboxProps>`
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
  border-radius: 0.25rem;
  transition: all 100ms;
  margin-right: 0.5rem;
  background: ${ifProp(
    'checked',
    palette('checkbox', 'checked', 0),
    theme('palette.checkbox.default.0'),
  )};
  border: 1px solid
    ${ifProp(
      'checked',
      palette('checkbox', 'checked', 1),
      theme('palette.checkbox.default.1'),
    )};

  &:hover {
    background: ${ifProp(
      'checked',
      palette('checkbox', 'checked', 2),
      theme('palette.checkbox.default.2'),
    )};
    border: 1px solid
      ${ifProp(
        'checked',
        palette('checkbox', 'checked', 3),
        theme('palette.checkbox.default.3'),
      )};
  }

  svg {
    visibility: ${ifProp('checked', 'visible', 'hidden')};
    position: absolute;
    top: -1px;
    left: -1px;
  }
`;

export const CheckboxLabel = styled.div`
  font-size: 15px;
  line-height: 18px;
  vertical-align: top;
`;

export const Checkbox = ({
  checked,
  label,
  onChange,
  value,
  name,
  ...props
}: CheckboxProps) => (
  <CheckboxContainer>
    <HiddenCheckbox
      checked={checked}
      name={name}
      {...props}
      onChange={event => {
        onChange(name, value, event.target.checked);
      }}
      value={value}
    />
    <StyledCheckbox checked={checked}>
      <CheckboxIcon />
    </StyledCheckbox>
    {label ? <CheckboxLabel>{label}</CheckboxLabel> : null}
  </CheckboxContainer>
);

Checkbox.defaultProps = {
  checked: false,
  label: '',
};
