import { Team } from '../types/Team';

type ImageType = 'coach' | 'team' | 'competition' | 'country' | 'player';

export function getImageSource(
  type: ImageType,
  id?: number | null,
  size?: number,
) {
  switch (type) {
    case 'coach': {
      return `https://s.hs-data.com/gfx/person/${size || 250}x${
        size || 250
      }/${id}.jpg`;
    }
    case 'player': {
      return `https://s.hs-data.com/gfx/person/${size || 250}x${
        size || 250
      }/${id}.jpg`;
    }
    case 'team': {
      return `https://s.hs-data.com/gfx/emblem/common/${size || 20}x${
        size || 20
      }/${id}.png`;
    }
    case 'competition': {
      return `https://s.hs-data.com/gfx/competition/png/normal/${size || 20}x${
        size || 20
      }/${id}.png`;
    }
    case 'country': {
      return `https://s.hs-data.com/gfx/flags/${size || 20}x${
        size || 20
      }/${id}.png`;
      /*return `https://s.hs-data.com/gfx/flags/common/svg/${id}.svg`;*/
    }
  }
}

export function getTeamImageSource(team?: Team | null, size?: number) {
  if (team?.type === 'national')
    return getImageSource('country', team?.country_id, size);
  return getImageSource('team', team?.id, size);
}

export function getFeedImageSource(path) {
  return `https://s.hs-data.com${path}`;
}
