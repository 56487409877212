import { call, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import { trainerTickerActions as actions } from '.';
import { api } from '../../../../services/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { GetAction, TickersResponse } from './types';

function* getTickers(action: PayloadAction<GetAction>) {
  try {
    let days: 7 | 30 = 7;
    const {
      take = 1001,
      skip = 0,
      promiseResolver = () => {},
    } = action.payload || {};

    let response: AxiosResponse<TickersResponse> = yield call(
      api.get,
      `/trainer-ticker`,
      {
        params: { take, skip, period_in_days: days },
      },
    );

    if (!response?.data?.data?.length) {
      days = 30;
      response = yield call(api.get, `/trainer-ticker`, {
        params: { take, skip, period_in_days: days },
      });
    }

    yield put(
      actions.getTickersSuccess({
        tickers: response.data.data,
        total: response.data.length,
        days,
      }),
    );
    promiseResolver && promiseResolver();
  } catch (error) {
    console.error(error);
    yield put(actions.getTickersError());
  }
}

export function* trainerTickerSaga() {
  yield takeLatest(actions.getTickers.type, getTickers);
}
