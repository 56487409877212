import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { reportSectionsOldSaga } from './saga';
import { ReportSectionsStateOld, UpdateReportSectionsAction } from './types';
import { ReportSectionEntry } from '../../../../pages/ReportConstructorPage/types';

export const initialState: ReportSectionsStateOld = {
  reportSections: [],
  getLoading: false,
  updateLoading: false,
};

const slice = createSlice({
  name: 'reportSectionsOld',
  initialState,
  reducers: {
    getReportSections(state) {
      state.getLoading = true;
    },
    getReportSectionsSuccess(
      state,
      action: PayloadAction<ReportSectionEntry[]>,
    ) {
      state.getLoading = false;
      state.reportSections = action.payload;
    },
    getReportSectionsError(state) {
      state.getLoading = false;
    },

    updateReportSections(
      state,
      action: PayloadAction<UpdateReportSectionsAction>,
    ) {
      state.updateLoading = true;
    },
    updateReportSectionsSuccess(
      state,
      action: PayloadAction<ReportSectionEntry[]>,
    ) {
      state.updateLoading = false;
      state.reportSections = action.payload;
    },
    updateReportSectionsError(state) {
      state.updateLoading = false;
    },
  },
});

export const { actions: reportSectionsActions } = slice;

export const useReportSectionsSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: reportSectionsOldSaga });
  return { actions: slice.actions };
};
