import React from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

import { Icon } from '../Icon';
import { Col, Row } from '../Grid';

export function PageError() {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Row>
        <Col xs={12}>
          <Icon name="no-search-result" />
        </Col>
        <Col xs={12} gutterY={0.75}>
          {t('formErrorMessage.pageError')}
        </Col>
      </Row>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 320px;
  opacity: 0.5;
  text-align: center;
  white-space: pre-line;
  margin: 1rem 0;
`;
