import styled, { keyframes } from 'styled-components';
import { palette } from '../../../styles/tools';

const spinKeyframe = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Loader = styled.div`
  background: ${palette('loader', 'default', 0)};
  animation: ${fadeIn} 0.2s ease-out;

  &:after {
    content: '';
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: solid 4px ${palette('loader', 'default', 2)};
    border-top-color: ${palette('loader', 'default', 1)};
    animation: ${spinKeyframe} 1s infinite linear, ${fadeIn} 0.2s ease-out;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -20px 0 0 -20px;
  }
`;
