import React, { memo } from 'react';
import OriginAsyncSelect from 'react-select/async-creatable';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { api } from '../../../services/api';
import { debounce } from '../../../utils/debounce';
import { Coach } from '../../../types/Coach';
import { OptionComponent } from './components/Option';
import { ControlComponent } from './components/Control';
import { searchSelectStyles } from './styles';
import { useCustomCoachSlice } from '../CustomCoach/slice';
import { Option } from '../Select/types';
import { getTranslated } from '../../../utils/get-translated';
import { ContainerComponent } from './components/Container';
import { Team } from '../../../types/Team';
import { HS_SearchPlayer } from '../../../types/Player';

interface CoachesResponse {
  total: number;
  coaches: Coach[];
}

interface TeamsResponse {
  total: number;
  teams: Team[];
}

interface PlayersResponse {
  total: number;
  data: HS_SearchPlayer[];
}

export type SearchOption = Option &
  (
    | {
        type: 'coach';
        data: Coach;
      }
    | {
        type: 'team';
        data: Team;
      }
    | {
        type: 'player';
        data: HS_SearchPlayer;
      }
  );

const coachToOption = (coach: Coach): SearchOption => ({
  value: coach.id,
  label: `${coach.firstname} ${coach.surname}`,
  data: coach,
  type: 'coach',
});

const teamToOption = (team: Team): SearchOption => ({
  value: team.id,
  label: getTranslated(team, 'name_en'),
  data: team,
  type: 'team',
});

const playerToOption = (player: HS_SearchPlayer): SearchOption => ({
  value: player.id,
  label: `${player.firstname} ${player.surname}`,
  data: player,
  type: 'player',
});

export const Search = memo(function Search() {
  const { t } = useTranslation();
  const history = useHistory();
  const { actions } = useCustomCoachSlice();
  const dispatch = useDispatch();

  const _promiseOptions = async (inputValue: string = ' ') => {
    try {
      const params = { take: 20, skip: 0 };

      const [coachesResponse, teamsResponse, playersResponse] =
        await Promise.all([
          api.post<CoachesResponse>(
            `/coaches?q=${inputValue}`,
            {
              search: inputValue || '',
            },
            {
              params,
            },
          ),
          api.get<TeamsResponse>(`/teams`, {
            params: {
              ...params,
              needle: inputValue || '',
            },
          }),
          api.get<PlayersResponse>(`/player`, {
            params: {
              ...params,
              needle: inputValue || '',
            },
          }),
        ]);

      return [
        {
          label: t('label.coaches'),
          options: coachesResponse.data.coaches.map(coachToOption),
        },
        {
          label: t('label.teams'),
          options: teamsResponse.data.teams.map(teamToOption),
        },
        {
          label: t('label.players'),
          options: playersResponse.data.data.map(playerToOption),
        },
      ];
    } catch (error) {
      console.error(error);
      return [];
    }
  };
  const promiseOptions = debounce(_promiseOptions, 300);

  const onChange = option => {
    const { type, value } = option;
    history.push(`/${type}/${value}`);
  };

  const onCreate = () => {
    dispatch(actions.openForm());
  };

  return (
    <Wrapper>
      <SearchSelect
        value={null}
        classNamePrefix="react-select"
        onChange={onChange}
        // @ts-ignore
        loadOptions={promiseOptions}
        components={{
          DropdownIndicator: null,
          Option: OptionComponent,
          Control: ControlComponent,
          SelectContainer: ContainerComponent,
        }}
        placeholder={t('formPlaceholder.search')}
        noOptionsMessage={({ inputValue }) =>
          inputValue
            ? t('formPlaceholder.noOptions')
            : t('formPlaceholder.typeToSearch')
        }
        cacheOptions
        onCreateOption={onCreate}
      />
    </Wrapper>
  );
});

const Wrapper = styled.div``;

const SearchSelect = styled(OriginAsyncSelect)`
  ${searchSelectStyles}
`;
