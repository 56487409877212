import { all, call, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';

import { reportSectionsActions as actions } from '.';
import { api } from '../../../../../services/api';
import { ReportSectionField, UpdateReportSectionsAction } from './types';
import { ReportSectionEntry } from '../../../../pages/ReportConstructorPage/types';

function* getReportSections() {
  try {
    const response: AxiosResponse<ReportSectionEntry[]> = yield call(
      api.get,
      '/report-sections',
    );

    yield put(actions.getReportSectionsSuccess(response.data));
  } catch (err) {
    yield put(actions.getReportSectionsError());
  }
}

function* updateReportSections(
  action: PayloadAction<UpdateReportSectionsAction>,
) {
  const { sections, callback } = action.payload;

  try {
    let toCreate: ReportSectionField[] = [];
    let toUpdate: ReportSectionField[] = [];
    let toDelete: ReportSectionField[] = [];

    for (let i = 0; i < sections.length; i++) {
      const { id, _deleted, _new } = sections[i];

      if (_deleted) {
        if (id) {
          toDelete.push(sections[i]);
        }
      } else if (_new) {
        toCreate.push({
          ...sections[i],
          sort: i + 1,
        });
      } else {
        toUpdate.push({
          ...sections[i],
          sort: i + 1,
        });
      }
    }

    yield all([
      ...toCreate.map(
        ({ id, name, description, maxrating, sort, ratingtype, type_id }) =>
          call(api.post, `/report-sections`, {
            type_id,
            name,
            description,
            sort,
            maxrating: maxrating || null,
            haverating: !!ratingtype?.value,
            ratingtype: ratingtype?.value,
          }),
      ),
      ...toUpdate.map(
        ({ id, name, description, maxrating, sort, ratingtype, type_id }) =>
          call(api.patch, `/report-sections/${id}`, {
            type_id,
            name,
            description,
            sort,
            maxrating: maxrating || null,
            haverating: !!ratingtype?.value,
            ratingtype: ratingtype?.value,
          }),
      ),
      ...toDelete.map(({ id }) => call(api.delete, `/report-sections/${id}`)),
    ]);

    const response: AxiosResponse<ReportSectionEntry[]> = yield call(
      api.get,
      '/report-sections',
    );

    yield put(actions.updateReportSectionsSuccess(response.data));
  } catch (error) {
    console.error(error);
    yield put(actions.updateReportSectionsError());
  } finally {
    callback();
  }
}

export function* reportSectionsOldSaga() {
  yield takeLatest(actions.getReportSections.type, getReportSections);
  yield takeLatest(actions.updateReportSections.type, updateReportSections);
}
