import React, { PropsWithChildren } from 'react';

import { UserValidateProps } from '../../../utils/validate-user-permissions';
import { usePermissions } from '../../../hooks/usePermissions';

export function PrivateBlock(props: PropsWithChildren<UserValidateProps>) {
  const { children, permissions, tags } = props;
  const hasAccess = usePermissions({
    permissions,
    tags,
  });

  if (!hasAccess) return null;

  return <>{children}</>;
}
