import { Option } from '../../Select/types';
import { Coach } from '../../../../types/Coach';
import { Media } from '../../../../types/Media';

export enum ErrorType {
  SERVER_ERROR = 1,
  COACH_EXISTS,
}

export interface FormFields {
  id?: number;
  firstname: string;
  surname: string;
  current_team: Option | null;
  coach_type: Option | null;
  country: Option | null;
  birthday: Date | '';
  contract: Date | '';
  media: { file: File } | Media | null;
  current_license: Option | null;
  languages: Option[] | null;
}

export interface RequestBody {
  firstname: string;
  surname: string;
  current_team_id: number | null;
  coach_type_id: number | null;
  country_id: number | null;
  birthday: Date | '';
  contract: Date | '';
  media_id: number | null;
  current_license_id: number | null;
  languages: number[] | null;
}

export interface CreateAction {
  values: FormFields;
  callback: (coach: Coach) => void;
}

export interface CustomCoachState {
  initialValues: FormFields;
  isOpen: boolean;
  loading: boolean;
  error: ErrorType | null;
}
