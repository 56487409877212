import { useContext, useMemo } from 'react';
import { PermissionType, RoleTagType } from '../types/Dict';
import { validateRoutePermissions } from '../utils/validate-user-permissions';
import { AuthContext } from '../context/AuthContext';

interface Props {
  permissions?: PermissionType[];
  tags?: (RoleTagType | `!${RoleTagType}`)[];
}

export function usePermissions(props: Props) {
  const { permissions, tags } = props;
  const { user } = useContext(AuthContext);

  const { hasAllPermissions, hasAllTags } = useMemo(() => {
    return validateRoutePermissions({
      user,
      permissions,
      tags,
    });
  }, [user?.id]);

  return hasAllPermissions && hasAllTags;
}
