import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from './index';

const selectProfile = (state: RootState) => state.profile || initialState;

export const selectUser = createSelector([selectProfile], state => state.user);

export const selectGetUserLoading = createSelector(
  [selectProfile],
  state => state.getLoading,
);

export const selectUpdateUserLoading = createSelector(
  [selectProfile],
  state => state.updateLoading,
);

export const selectUserClientCountryId = createSelector(
  [selectProfile],
  state => state.user?.client?.countryid,
);
