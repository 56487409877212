import React, { createContext, ReactNode, useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { api } from '../../services/api';
import { setAuthorizationHeader } from '../../services/interceptors';
import {
  createDeviceId,
  createTokenCookies,
  getAccessToken,
  getDeviceId,
  removeTokenCookies,
} from '../../utils/auth-cookies';
import { Language, Role } from '../../types/Dict';
import { Client } from '../../types/Client';
import { bootIntercom, shutdownIntercom } from '../../services/intercom';

interface User {
  id: number;
  email: string;
  clientid: number;
  client: Client;
  role: Role;
  firstname: string;
  lastname: string;
  language: Language;
  lastloggedin: string | null;
  coach_profile_id: number;
  coach_accepted_terms: boolean | null;
}

interface SignInCredentials {
  email: string;
  password: string;
}

interface OtpVerify {
  email: string;
  secret: string;
}

interface SignInResponse {
  accessToken: string | null;
  refreshToken: string | null;
  user: User | null;
  twoFactorAuth: boolean;
}

interface OtpResponse {
  accessToken: string | null;
  refreshToken: string | null;
  user: User | null;
}

interface BrowserLocation {
  pathname: string;
}

interface AuthContextData {
  signIn: (
    credentials: SignInCredentials,
    from: BrowserLocation | null,
  ) => Promise<void | AxiosError>;
  otpVerify: (
    params: OtpVerify,
    from: BrowserLocation | null,
  ) => Promise<void | AxiosError<{ message: string }>>;
  signOut: () => void;
  user: User;
  isAuthenticated: boolean;
  loadingUserData: boolean;
}

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthContext = createContext({} as AuthContextData);

export function AuthProvider({ children }: AuthProviderProps) {
  const [user, setUser] = useState<User | null>();
  const [loadingUserData, setLoadingUserData] = useState(true);
  const history = useHistory();
  const { pathname, search } = useLocation();
  const accessToken = getAccessToken();
  const isAuthenticated = Boolean(user);
  const userData = user as User;
  const { i18n } = useTranslation();
  const deviceId = getDeviceId();

  async function signIn(
    { email, password }: SignInCredentials,
    from: BrowserLocation | null = null,
  ) {
    try {
      const response = await api.post<SignInResponse>('/auth/signin', {
        email,
        password,
        device_id: deviceId,
      });
      const { accessToken, refreshToken, user, twoFactorAuth } = response.data;

      if (twoFactorAuth) {
        history.push({
          pathname: '/auth/otp/verify',
          state: {
            email,
            password,
            from,
          },
        });
      } else if (user && accessToken && refreshToken) {
        createTokenCookies(accessToken, refreshToken);
        setUser(user);
        await i18n.changeLanguage(user.language.code || 'en');
        // @ts-ignore
        setAuthorizationHeader(api.defaults, accessToken);
        history.push(from || '/');
        bootIntercom(user);
      }
    } catch (error) {
      const err = error as AxiosError;
      return err;
    }
  }

  async function otpVerify(
    { secret, email }: OtpVerify,
    from: BrowserLocation | null = null,
  ) {
    try {
      const response = await api.post<OtpResponse>(`/auth/2fa`, {
        email,
        secret,
        device_id: deviceId,
      });
      const { accessToken, refreshToken, user } = response.data;

      if (user && accessToken && refreshToken) {
        createTokenCookies(accessToken, refreshToken);
        setUser(user);
        await i18n.changeLanguage(user.language.code || 'en');
        // @ts-ignore
        setAuthorizationHeader(api.defaults, accessToken);
        history.push(from || '/');
        bootIntercom(user);
      }
    } catch (error) {
      const err = error as AxiosError<{ message: string }>;
      return err;
    }
  }

  function signOut() {
    api.get(`/auth/logout`);
    flashUserData();
  }

  function flashUserData(newPathname = '/auth/login') {
    removeTokenCookies();
    setUser(null);
    setLoadingUserData(false);
    shutdownIntercom();

    if (newPathname !== pathname) {
      history.push({
        pathname,
        search,
      });
    }
  }

  useEffect(() => {
    if (!deviceId) createDeviceId();
  }, []);

  useEffect(() => {
    if (!accessToken) flashUserData(pathname);
  }, [pathname, accessToken]);

  useEffect(() => {
    const accessToken = getAccessToken();

    async function getUserData() {
      setLoadingUserData(true);

      try {
        const { data: user } = await api.get<User>('/auth/iam');

        if (user) {
          setUser(user);
          await i18n.changeLanguage(user?.language?.code || 'en');
          bootIntercom(user);
        }
      } catch (error) {
        flashUserData();
      }

      setLoadingUserData(false);
    }

    if (accessToken) {
      // @ts-ignore
      setAuthorizationHeader(api.defaults, accessToken);
      getUserData();
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user: userData,
        loadingUserData,
        signIn,
        signOut,
        otpVerify,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
