declare global {
  interface Window {
    Intercom: any;
  }
}

const app_id = process.env.REACT_APP_INTERCOM_APP_ID;

export function bootIntercom(user?: {
  firstname: string;
  lastname: string;
  email: string;
}) {
  if (window.Intercom && user && app_id) {
    window.Intercom('boot', {
      api_base: 'https://api-iam.intercom.io',
      app_id,
      name: `${user.firstname} ${user.lastname}`,
      email: user.email,
    });
  }
}

export function shutdownIntercom() {
  if (window.Intercom) {
    window.Intercom('shutdown');
  }
}
