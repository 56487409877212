import * as React from 'react';
import styled, { css } from 'styled-components/macro';
import { NavLink as RouterNavLink, NavLinkProps } from 'react-router-dom';

import { AuthContext } from '../../../context/AuthContext';
import { validateUserPermissions } from '../../../utils/validate-user-permissions';
import { PermissionType } from '../../../types/Dict';
import { palette } from '../../../styles/tools';
import { UserValidateProps } from '../../../utils/validate-user-permissions';
import { usePermissions } from '../../../hooks/usePermissions';

type LinkProps = NavLinkProps & UserValidateProps;

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
  UserValidateProps;

export const NavLink = ({ permissions, tags, ...props }: LinkProps) => {
  const hasAccess = usePermissions({ permissions, tags });

  if (!hasAccess) return null;

  return <NavLinkStyled {...props} />;
};

export const NavButton = ({ permissions, tags, ...props }: ButtonProps) => {
  const hasAccess = usePermissions({ permissions, tags });

  if (!hasAccess) return null;

  return <NavButtonStyled {...props} />;
};

const NavItemStyle = css`
  padding: 4px 4px;
  margin: 0 4px;
  text-align: center;
  text-transform: uppercase;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.8px;
  color: ${palette('link', 'default', 0)};
  border-radius: 4px;
  border: none;
  cursor: pointer;

  @media only screen and (min-width: 1280px) {
    padding: 4px 8px;
    margin: 0 8px;
  }

  &:hover {
    color: ${palette('link', 'default', 1)};
  }

  &.active {
    color: ${palette('link', 'default', 2)};
  }

  svg {
    margin-bottom: 2px;
  }
`;

export const NavLinkStyled = styled(RouterNavLink)`
  ${NavItemStyle}
`;

export const NavButtonStyled = styled.button`
  ${NavItemStyle}
`;
