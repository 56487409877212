import styled, { css } from 'styled-components/macro';
import { ifProp } from 'styled-tools';
import { keyframes } from 'styled-components';
import { IconWrapper } from '../Icon';
import { palette, PaletteProps } from '../../../styles/tools';

interface ButtonIconProps extends PaletteProps {
  $loading?: boolean;
}

const spinKeyframe = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

export const ButtonIcon = styled.button<ButtonIconProps>`
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  line-height: 0;
  color: ${ifProp('$loading', 'transparent', 'inherit')};
  pointer-events: ${ifProp('$loading', 'none', 'auto')};
  position: relative;
  background: ${palette('button', 'default', 0)};
  color: ${palette('button', 'default', 2)};

  @media print {
    display: none;
  }

  ${ifProp(
    'palette',
    css`
      padding: 10px;
      border-radius: 6px;
    `,
  )}

  ${ifProp(
    ({ palette }) => palette !== 'active',
    css`
      &:hover {
        background: ${palette('button', 'default', 1)};
        transition: background-color 100ms ease-out;
        color: ${palette('button', 'default', 3)};
      }
    `,
  )}

  svg {
    width: 24px;
    height: 24px;
  }

  ${ifProp(
    '$loading',
    css`
      & > ${IconWrapper} {
        opacity: 0;
      }

      &:after {
        content: '';
        display: block;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: solid 2px rgba(255, 255, 255, 0.2);
        border-top-color: #fff;
        animation: ${spinKeyframe} 1s infinite linear;
        position: absolute;
        left: 50%;
        top: 50%;
        margin: -10px 0 0 -10px;
      }
    `,
  )}
`;
