// @flow
import prop from './prop';
import type { Needle, PropsFn } from './types';

/**
 * Switches on a given prop. Returns the value or function for a given prop value. Third parameter is default value.
 * @example
 * import styled, { css } from "styled-components";
 * import { switchProp, prop } from "styled-tools";
 *
 * const Button = styled.button`
 *   font-size: ${switchProp(prop("size", "medium"), {
 *     small: prop("theme.sizes.sm", "12px"),
 *     medium: prop("theme.sizes.md", "16px"),
 *     large: prop("theme.sizes.lg", "20px")
 *   }, prop("theme.sizes.md", "16px"))};
 *   ${switchProp("theme.kind", {
 *     light: css`
 *       color: LightBlue;
 *     `,
 *     dark: css`
 *       color: DarkBlue;
 *     `
 *   }, css`color: black;`)}
 * `;
 *
 * <Button size="large" theme={{ kind: "light" }} />
 */
export const switchProp =
  (needle: Needle, cases: Object | PropsFn, defaultCase: any): PropsFn =>
  (props = {}) => {
    const value =
      typeof needle === 'function' ? needle(props) : prop(needle)(props);
    const finalCases = typeof cases === 'function' ? cases(props) : cases;
    if (value in finalCases) {
      return finalCases[value];
    }
    return defaultCase;
  };

export default switchProp;
