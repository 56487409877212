import { components } from 'react-select';
import React, { useMemo } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

import { Coach } from '../../../../types/Coach';
import { CircleImage } from '../../CircleImage';
import { Icon, IconWrapper } from '../../Icon';
import { getImageSource } from '../../../../utils/imageSource';
import PersonPlaceholder from '../../../components/Icon/assets/person-placeholder.svg';
import { getFileLink } from '../../../../utils/get-file-link';
import { Img, ImgStyled } from '../../Img';
import { theme } from '../../../../styles/tools';
import { HS_SearchPlayer } from '../../../../types/Player';
import { formatDate, parseDate } from '../../../../utils/date';
import { Col, Row } from '../../Grid';
import { Small } from '../../Small';
import { Meta2 } from '../../Meta';
import { Ellipsis } from '../../Ellipsis';
import { Team } from '../../../../types/Team';
import { getTranslated } from '../../../../utils/get-translated';

export const OptionComponent = props => {
  const { t } = useTranslation();

  if (props.data.__isNew__) {
    return (
      <components.Option {...props}>
        <CreateOption>
          <Icon name="plus" />
          {t('buttonAction.addMissingCoach')}
        </CreateOption>
      </components.Option>
    );
  }

  if (props.data.type === 'team') {
    const team = props.data.data as Team;
    const { id, gender } = team;

    return (
      <components.Option {...props}>
        <Wrapper>
          <Img
            imgSize={24}
            src={getImageSource('team', id, 30)}
            placeholder="team"
          />
          {getTranslated(team)}{' '}
          {gender ? `(${gender.slice(0, 1).toUpperCase()})` : ''}
          <Icon name="chevron" />
        </Wrapper>
      </components.Option>
    );
  }

  if (props.data.type === 'player') {
    const player = props.data.data as HS_SearchPlayer;
    const { firstname, surname, id, team, team_id, birthday } = player;
    const birthdayNormalized = formatDate(
      parseDate(birthday, 'yyyy-MM-dd'),
      'dd.MM.yyyy',
    );
    const imageSrc = getImageSource('player', +id);

    return (
      <components.Option {...props}>
        <Padding>
          <Row gutter={0.5} alignItems="center">
            <Col gutter={0.5} alignSelf="flex-start">
              <CircleImage
                size={24}
                imageSrc={imageSrc}
                style={{
                  backgroundImage: `url(${imageSrc}), url(${PersonPlaceholder})`,
                }}
              />
            </Col>
            <Col gutter={0.5} xs="auto">
              <Row>
                <Col xs={12}>
                  {firstname} {surname}
                  <Icon name="chevron" />
                </Col>
                <Col xs={12}>
                  <Small>{birthdayNormalized}</Small>
                </Col>
                <Col xs={12}>
                  <Meta2
                    icon={
                      <Img
                        imgSize={14}
                        src={getImageSource('team', +team_id)}
                        placeholder="team"
                      />
                    }
                  >
                    <Small>
                      <Ellipsis>{team}</Ellipsis>
                    </Small>
                  </Meta2>
                </Col>
              </Row>
            </Col>
          </Row>
        </Padding>
      </components.Option>
    );
  }

  const coach = props.data.data as Coach;
  const { firstname, surname, id, is_custom, media } = coach;
  const imageSrc = is_custom ? getFileLink(media) : getImageSource('coach', id);

  return (
    <components.Option {...props}>
      <Wrapper>
        <CircleImage
          size={24}
          imageSrc={imageSrc}
          style={{
            backgroundImage: `url(${imageSrc}), url(${PersonPlaceholder})`,
          }}
        />
        {firstname} {surname}
        <Icon name="chevron" />
      </Wrapper>
    </components.Option>
  );
};

const Padding = styled.div`
  padding: 0.75rem 1rem;

  & ${IconWrapper}[name="chevron"] {
    position: absolute;
    transform: rotate(-90deg);
    margin-top: 2px;
    margin-left: 4px;
  }

  ${Small} {
    display: block;
    opacity: 0.7;
  }
`;

const Wrapper = styled.div`
  padding: 0.75rem 1rem 0.75rem 3rem;
  white-space: nowrap;
  position: relative;
  display: block;

  & > ${CircleImage}, & > ${ImgStyled} {
    position: absolute;
    top: 0.75rem;
    left: 1rem;
  }

  & ${IconWrapper}[name="chevron"] {
    position: absolute;
    transform: rotate(-90deg);
    margin-top: 2px;
    margin-left: 4px;
  }
`;

const CreateOption = styled.button`
  display: block;
  border: none;
  position: relative;
  padding: 0.75rem 1rem 0.75rem 3rem;
  border-top: 1px solid ${theme('palette.hr.default.0')};
  width: 100%;
  text-align: left;

  svg {
    position: absolute;
    top: 0.75rem;
    left: 1rem;
    width: 1.5rem;
    height: 1.5rem;
  }
`;
