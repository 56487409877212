const apiKey = process.env.REACT_APP_USERSNAP_API_KEY;

export function initUsersnap() {
  try {
    if (apiKey) {
      //@ts-ignore
      window.onUsersnapLoad = function (api) {
        api.init();
      };
      const script = document.createElement('script');
      script.defer = true;
      script.src = `https://widget.usersnap.com/global/load/${apiKey}?onload=onUsersnapLoad`;
      document.getElementsByTagName('head')[0].appendChild(script);
    }
  } catch (err: any) {
    console.error(err);
  }
}
