import { v4 as uuidv4 } from 'uuid';

const ACCESS_TOKEN_COOKIE = 'auth.accessToken';
const REFRESH_TOKEN_COOKIE = 'auth.refreshToken';
const DEVICE_ID_COOKIE = 'auth.deviceId';

export function createTokenCookies(accessToken: string, refreshToken: string) {
  localStorage.setItem(ACCESS_TOKEN_COOKIE, accessToken);
  localStorage.setItem(REFRESH_TOKEN_COOKIE, refreshToken);
}

export function removeTokenCookies() {
  localStorage.removeItem(ACCESS_TOKEN_COOKIE);
  localStorage.removeItem(REFRESH_TOKEN_COOKIE);
}

export function getAccessToken() {
  return localStorage.getItem(ACCESS_TOKEN_COOKIE);
}

export function getRefreshToken() {
  return localStorage.getItem(REFRESH_TOKEN_COOKIE);
}

export function createDeviceId() {
  const deviceId = uuidv4();
  localStorage.setItem(DEVICE_ID_COOKIE, deviceId);
}

export function getDeviceId() {
  return localStorage.getItem(DEVICE_ID_COOKIE);
}

export function removeDeviceId() {
  localStorage.removeItem(DEVICE_ID_COOKIE);
}
