import { Media } from '../types/Media';

import { getRefreshToken } from './auth-cookies';

export function getFileLink(media: Media | null | undefined) {
  const refreshToken = getRefreshToken();

  if (!media || !refreshToken) return '';

  return `${process.env.REACT_APP_API_URL}/file/${media.user_id}/${media.key}?access_token=${refreshToken}`;
}
