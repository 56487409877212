import ModalOrigin, { BaseModalBackground } from 'styled-react-modal';
import styled, { css, keyframes } from 'styled-components/macro';
import { ifProp, prop } from 'styled-tools';

import { cardStyles } from '../Card';
import React, { useState } from 'react';
import { palette } from '../../../styles/tools';

interface Props {
  isOpen?: boolean;
  children: any;
  type?: 'sidebar';
  width?: number;
  offset?: number;
  overflow?: 'hidden' | 'auto';
  onBackgroundClick?: (event: React.MouseEvent<HTMLElement>) => void;
  onEscapeKeydown?: (event: React.KeyboardEvent) => void;
}

const rightToLeft = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const ModalBackground = styled(BaseModalBackground)`
  background: ${palette('modal', 'default')};
  animation: ${fadeIn} 0.2s ease-out;
  top: 0;
  z-index: 2;
`;

export const ModalStyled = ModalOrigin.styled<Props>`
  ${cardStyles}
  padding: 30px;
  width: ${props => props.width || 426}px;
  overflow: ${prop('overflow', 'auto')};
  max-height: 100vh;

  ${props => {
    if (props.type === 'sidebar') {
      return css`
        transition: right 0.2s;
        position: fixed;
        top: 0;
        right: -${props.offset || 0}px;
        bottom: 0;
        max-width: 100%;
        border-radius: 0;
        animation: ${rightToLeft} 0.2s ease-out;
      `;
    }
  }}
`;

export const Modal = ({ onBackgroundClick, ...props }: Props) => {
  const [mouseDownTarget, setMouseDownTarget] = useState(null);

  const backgroundClickHandler = event => {
    if (event.target === mouseDownTarget && onBackgroundClick) {
      onBackgroundClick(event);
    }
    setMouseDownTarget(null);
  };

  const onBackgroundMouseDownHandler = event => {
    setMouseDownTarget(event.target);
  };

  return (
    <ModalStyled
      onBackgroundClick={backgroundClickHandler}
      backgroundProps={{
        onMouseDown: onBackgroundMouseDownHandler,
      }}
      {...props}
    />
  );
};
