import { css } from 'styled-components';
import { ifProp } from 'styled-tools';

import { StyledCheckbox } from '../Checkbox';
import { palette, theme } from '../../../styles/tools';

export const selectMenuStyles = css`
  .react-select__menu {
    background: ${palette('select', 'default', 2)};
    margin: 0;
    border-radius: 0 0 6px 6px;
    line-height: 18px;
    font-size: 15px;
    overflow: hidden;
    padding-top: 1px;
    box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.3);
  }

  .react-select__group-heading {
    font-size: 13px;
    font-style: normal;
    line-height: 16px;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    padding: 0 1rem;
    margin: 0;
    color: ${theme('palette.label.default.0')};
  }

  .react-select__menu-list {
    padding: 0;
  }

  .react-select__option {
    padding: 8px 16px;
    position: relative;
    font-size: 14px;

    &.react-select__option--is-focused {
      background: ${palette('select', 'default', 7)};
    }

    &.react-select__option--is-selected {
      background: ${palette('select', 'default', 8)} !important;
    }
  }

  .react-select__loading-indicator {
    color: ${palette('select', 'default', 9)};
  }
`;

export const selectStyles = css`
  .react-select__control {
    min-height: 44px;
    border: 1px solid
      ${ifProp(
        'invalid',
        theme('palette.select.invalid.1'),
        palette('select', 'default', 1),
      )} !important;
    background-color: ${palette('select', 'default', 0)};
    font-size: 15px;
    line-height: 18px;
    box-shadow: none;
    border-radius: 6px;
    transition: none;
    outline: none;

    &.react-select__control--menu-is-open {
      background: ${palette('select', 'default', 3)};
      border-radius: 6px 6px 0 0;

      .react-select__dropdown-indicator {
        transform: rotate(180deg);
      }
    }

    &.react-select__control--is-disabled {
      opacity: 0.5;
    }
  }

  .react-select__value-container {
    padding: 1px 13px;
  }

  .react-select__value-container--is-multi {
    padding: 7px 0 7px 7px;

    .react-select__multi-value {
      background: ${palette('select', 'default', 10)};

      .react-select__multi-value__label {
        color: ${palette('select', 'default', 11)};
        font-size: 14px;
        line-height: 18px;
      }

      .react-select__multi-value__remove {
        color: ${palette('select', 'default', 13)};

        &:hover {
          background: ${palette('select', 'default', 12)};
        }
      }
    }
  }

  .react-select__placeholder {
    color: ${palette('select', 'default', 4)};
  }

  .react-select__single-value {
    color: ${palette('select', 'default', 5)};
  }

  .react-select__input-container {
    color: ${palette('select', 'default', 5)};
  }

  .react-select__indicator {
    color: ${palette('select', 'default', 9)} !important;

    &.react-select__clear-indicator {
      &:hover {
        color: ${palette('select', 'default', 6)} !important;
      }
    }

    &.react-select__dropdown-indicator {
      transition: transform 0.2s;
    }
  }

  ${selectMenuStyles}
`;

export const multiSelectStyles = css`
  ${selectStyles}

  .react-select__menu-list--is-multi {
    .react-select__option {
      padding-left: 32px;

      &.react-select__option--is-focused {
        background: ${palette('select', 'default', 7)} !important;
      }

      &.react-select__option--is-selected {
        background: inherit !important;
        color: inherit !important;
      }

      ${StyledCheckbox} {
        position: absolute;
        left: 8px;
        top: 8px;
        vertical-align: middle;
      }
    }
  }
`;
