import React, { useMemo } from 'react';

import LogoLight from './assets/logo-light.png';
import LogoDark from './assets/logo-dark.png';
import { Img } from '../Img';
import { useSelector } from 'react-redux';
import { selectThemeKey } from '../../../styles/theme/slice/selectors';
import { isSystemDark } from '../../../styles/theme/utils';

interface Props {
  imgSize?: number;
}

const defaultProps = {
  imgSize: 32,
};

export function LogoImg(props: Props & typeof defaultProps) {
  const { imgSize } = props;
  const themeKey = useSelector(selectThemeKey);

  const theme = useMemo(() => {
    if (themeKey === 'system') {
      return isSystemDark ? 'dark' : 'light';
    }

    return themeKey;
  }, [themeKey]);

  return (
    <Img imgSize={imgSize} src={theme === 'dark' ? LogoLight : LogoDark} />
  );
}

LogoImg.defaultProps = defaultProps;
